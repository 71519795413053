/* topnav */
.topnav {
  background-color: black;
  color: white;
}
.topnav p {
  padding: 0%;
  margin: 0%;
}
.signup-mobile{
  display: none !important;
}
.nav-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 15px;
  height: 100%;
  color: black !important;
}

.main-navbar {
  height: 60px;
  background-color: #ffffff;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 3;
}

.logo {
  width: 10%;
}
.logo img {
  width: 50%;
}

.menu-icon {
  display: none;
}

.signup .btn-1{
  font-size: medium;
}

.nav-elements{
  display: flex;
  align-items: center;
}
.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 30px;
}

.nav-elements ul a{
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  text-decoration: none;
}
.nav-elements ul a.active {
  color: #000000;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}
.signup{
  display: flex;
}


@media (max-width: 768px) {
  .logo {
    width: 40%;
  }
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
  .signup-mobile{
    display: block !important;
  }
  .signup {
    display: none !important;
  }
  .nav-container {
    justify-content: space-between !important;
  }
}

@media (max-width: 850px) {
  .nav-container {
    justify-content: space-between;
  }
  .signup {
    display: none;
  }
  
  .menu-icon {
    display: flex;
    position: absolute;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #ffffff;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    flex-direction: column;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
  .signup-mobile{
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
}



.nav-elements .dropdown {
  position: relative;
}

.nav-elements .dropdown-menu {
  display: none;
  position: absolute;
  top: 90%;
  left: 0;
}

.nav-elements .dropdown:hover .dropdown-menu {
  display: block;
}

/* Style for the DropdownMenu */
.custom-dropdown-menu {
  background-color: white;
  color: black;
}

/* Style for each DropdownItem */
.custom-dropdown-item {
  color: black;
}

.custom-dropdown-item:hover {
  background-color: black;
  color: white;
}