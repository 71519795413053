.footer_container {
  margin-top: 10%;
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 0 2rem;
}
.footer-contents {
  margin: 3rem;
}
.footer-links a {
  color: #a7a5a5;
  text-decoration: none;
}
.footer-links a:hover {
  color: white;
}
a {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 768px) {
  .footer_container {
    margin-top: 20%;
  }
}