@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&display=swap');



.main-content{
    padding: 0 5em;
}
.btn-1{
    background-color: cyan;
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: large;
    border-radius: 5px;
    border: none;
    display: flex;
    width: fit-content;
    padding: 10px;
}
.btn-1:hover{
    background-color: rgb(0, 0, 0);
    color: cyan;
    transition: .5s;
}


.btn-2{
    background-color: black;
    color: white;
    text-decoration: none;
    font-size: x-large;
    padding: 10px 20px ;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    width:10em;
    transition: .4s;
}
.btn-2:hover{
    /* background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0); */
    background-color: cyan;
    color: black;
    width:11em;
}
.btn-2-typ-1 span{
    background-color: cyan;
    color: black;
    padding: 3px 10px;
    border-radius: 30px;
    display: flex;
    align-items: center;
}
.btn-2:hover span{
    background-color: rgb(0, 0, 0);
    color: cyan;
}

@media screen and (max-width: 768px) {
    .main-content{
        padding: 0;
    }
    .btn-2{
        font-size: large;
        padding: 10px 20px ;
    }
}