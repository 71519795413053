@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');


:root{
  --primary: cyan;
}


body {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans JP", sans-serif;
}

code
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul 
{
  list-style: none;
  padding: 0 !important;
} 
a {
  text-decoration: none;
}
h1,h2{
  font-family: "Alegreya", serif;
}